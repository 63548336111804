.modalBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: 0 solid transparent !important;
  border-radius: 8px;
  padding: 30px 20px !important;
  width: 70% !important;
  max-width: 2000px;
}

.select {
  height: 50px !important;
}
.selector {
  width: 100% !important;
}
.selectStyle {
  width: 15%;
  min-width: 80px !important;
}

.dateWithCheck {
  width: 65%;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
}

.dateWithCheck input {
  cursor: pointer;
  margin: 0;
}

.dateWithCheck label {
  position: absolute;
  cursor: pointer;
  bottom: -24px;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.dateWithCheck label p {
  margin: 0;
  padding: 0;
}

.amount {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  color: grey;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.button {
  height: 50px !important;
}

.accountInput {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 5px 0 10px !important;
}

.modalTable {
  z-index: 5;
  width: 50% !important;
  height: 100vh;
  border: none;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.accountInput {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.5);
}

.line {
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  height: 60%;
  margin-left: 18px;
}

.tableContainer {
  width: 100%;
  height: 100vh;
  border-top: 1px solid grey;
}

.mainHeader {
  width: 100%;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e88e5;
  height: 50px;
  color: white;

  cursor: default;
}

.header {
  width: 100%;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  background-color: white;
  color: #daa847;
  font-weight: bold;
  cursor: default;
}

.textContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
}

.textContainer {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: black;
  cursor: pointer;
  background-color: white;
  transition: 0.3s;
}
.serial {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid grey;
  height: 100%;
}
.text {
  width: 80%;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  height: 100%;
}
.text h3 {
  padding: 0 !important;
  margin: 0 !important;
}
.textContainer:hover {
  background-color: #f1f1f1;
  transition: 0.3s;
}

.selectedAccount {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  height: 25%;
  width: 30%;
}

.operationValidate {
  position: absolute;
}
.validationCss {
  position: relative;
  height: 50px;
  width: 35% !important;
  min-width: 130px !important;
  max-width: 300px !important;
}
.validationCss span {
  position: absolute;
  bottom: -23px;
  left: 0;
  color: red;
  font-size: 9px;
  font-weight: bold;
}
.operationPlaceholder {
  padding: 0 5px;
  background-color: white !important;
}

.destination {
  width: 100%;
  height: 100%;
  padding: 15px 5px !important;
  margin: 0 !important;
  resize: none;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: grey;
}

.deleteModal {
  width: 20%;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.deleteButton {
  height: 35px;
  width: 40%;
}

.passwordInput {
  border: 1px solid gray;
  height: 35px;
  width: 70%;
  min-width: 80px;
  max-width: 200px;
  border-radius: 5px;
  padding: 12px;
}
.searchCont {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0;
  display: flex;
  align-items: center;
  padding: 5px 0;
  background-color: white;
}
.searchInput {
  width: 100%;
  height: 50px;
  padding: 10px 10px 10px 15px;
}

@media screen and (max-width: 1000px) {
  /* .accountInput {
    max-width: 50px !important;
  } */
  .amount {
    width: 35px;
    height: 50px;
  }
  .select {
    height: 30px !important;
  }
  .selector {
    width: 50px !important;
  }
  .selectStyle {
    width: 15%;
    min-width: 50px !important;
  }
  .line {
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    height: 60%;
    margin-left: 5px !important;
  }

  .accountInput {
    height: 50px;
    padding-left: 5px !important;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 5px !important;
  }
  .accountInput img {
    margin: 2px !important;
  }
}

.accImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 45%;
}

.accValidation {
  width: 30%;
  min-width: 50px;
  max-width: 350px;
  position: relative;
}

.accValidation span {
  position: absolute;
  bottom: -23px;
  left: 0;
  color: red;
  font-size: 9px;
  font-weight: bold;
}

.DateTimePicker {
  width: 100%;
}

.operationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0 solid transparent !important;
  border-radius: 8px;
  gap: 35px;
  width: 40vw;
  max-width: 2000px;
  min-width: 550px;
}

.accountContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.dateContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
  gap: 15px;
}

.ammountValidation {
  position: relative;
  height: 50px;
  width: 38% !important;
  min-width: 130px !important;
  max-width: 300px !important;
}

.destinationValidation {
  position: relative;
  height: 50px;
  width: 100% !important;
  min-width: 130px !important;
}

.destinationValidation span {
  position: absolute;
  bottom: -23px;
  left: 0;
  color: red;
  font-size: 9px;
  font-weight: bold;
}

.ammountValidation span {
  position: absolute;
  bottom: -23px;
  left: 0;
  color: red;
  font-size: 9px;
  font-weight: bold;
}

.checkBoxContainer {
  width: 100%;
  display: flex;
}

.schemaInput {
  border: none;
  border-bottom: 1px solid black;
  margin-left: 15px;
}

.schemaInput:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}

.schemaModal {
  z-index: 5;
  overflow-y: scroll;
  width: 90% !important;
  height: 100vh;
  border: none;
  display: flex;
  flex-direction: column;
  padding-top: 0 !important;
}
.textContainer {
  display: flex;
  justify-content: flex-start;
  padding: 5px 10px;
  gap: 10px;
}

.textContainerSchema {
  display: flex;
  justify-content: flex-start;
  padding: 5px 10px;
  gap: 10px;
  flex-wrap: wrap;
  border: 1px solid black;
  border-top: none;
  cursor: pointer;
}
span span {
  font-weight: 500;
}

.buttonsDiv {
  width: 22%;
}
