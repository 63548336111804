.addButton {
  border: none;
  border-radius: 7px;
  background-color: rgb(65, 65, 193);
  color: white;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;
}

.tableContainer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.delete {
  scale: 1;
  padding: 0;
  margin: 0;
  height: 20px;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  transition: 0.3s;
}

.delete img {
  height: 100%;
  width: auto;
  transition: 0.3s;
}
.delete:hover {
  scale: 1.2;
  transition: 0.3s;
}
