.buttonsDiv {
  position: absolute;
  bottom: -85px;
  z-index: 3;
  right: 0;
  width: 100px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: #2c5595;
  padding: 10px 0;
}

.buttonsDiv button {
  cursor: pointer;
  border: 0;
  border-radius: 8%;
  background-color: transparent;
  width: 90%;
  color: white;
  padding: 5px 0;
}
.buttonsDiv button:hover {
  background-color: rgba(0, 0, 0, 0.227);
  transition: 0.3s;
}
